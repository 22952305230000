import * as RetrieveLogsBP from '../../blueprints/logs/retrieve-logs';
import React from 'react';
import { seamlessClient } from '../../seamless';
import { StoredLogItem } from '../../types/log';
import { FlamearrowTrace, StardateLogKind } from '@hiyllo/stardate/main';
import moment from 'moment';
import { Button } from '@hiyllo/ux/button';

const msToPixels = 0.1;

const TraceView = React.memo(function TraceView ({ trace, delay }: {
  trace: FlamearrowTrace
  delay: number
}): JSX.Element {
  return (
    <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        height: 20
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: delay * msToPixels
        }}>
          <div style={{
            height: 20,
            width: trace.time * msToPixels,
            background: 'blue',
            whiteSpace: 'nowrap'
          }}>
            <div>{trace.label} {trace.time}ms</div>
          </div>
        </div>
      </div>
      <div style={{
        marginLeft: (delay * msToPixels) - 2.5,
        borderLeft: '2.5px white solid'
      }}>
        {trace.subtraces.map((d, i) => (
          <TraceView key={i} trace={d[1]} delay={d[0]}/>
        ))}
      </div>
    </div>
  );
});

const LogRow = React.memo(function LogRow (props: {
    log: StoredLogItem
}): JSX.Element | null {
  const displayType = React.useContext(DisplayType);

  if (props.log.value.kind === StardateLogKind.latency) {
    throw new Error('latency log passed to LogView');
  }

  if (props.log.value.kind === StardateLogKind.flamearrow) {
    if (displayType === 'logs') {
      return null;
    }

    const trace = props.log.value.trace;
    return (
      <div style={{ overflowX: 'auto' }}>
        <div>{trace.label} ({trace.time}ms)</div>
        {trace.subtraces.map((d, i) => (
          <TraceView key={i} trace={d[1]} delay={d[0]}/>
        ))}
      </div>
    );
  }

  if (displayType === 'traces') {
    return null;
  }

  return (
    <div style={{
      flexDirection: 'row',
      alignItems: 'center',
      gap: 5
    }}>
      <div>
        {props.log.value.kind}
      </div>
      <div>
        <div style={{ padding: 5 }}>
          {props.log.value.message}
        </div>
        {props.log.value.kind === StardateLogKind.error
          ? <div style={{
            padding: 5,
            color: 'red',
            fontFamily: 'monospace',
            whiteSpace: 'pre-wrap'
          }}>
          Error: {JSON.stringify(props.log.value.error ?? {}, null, 2)}
          </div>
          : null}
        {props.log.value.kind !== StardateLogKind.trace
          ? <div>{JSON.stringify(props.log.value.data ?? {}, null, 2)}</div>
          : null}
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 10,
          padding: 5
        }}>
          <div style={{ flexShrink: 0 }}>
            <div>
              <b>Kind</b>
            </div>
            {props.log.value.kind}
          </div>
          <div style={{ flexShrink: 0 }}>
            <div>
              <b>Date</b>
            </div>
            {moment(props.log.date).format('hh:mm:ss a, MMM Do')}
          </div>
          <div style={{ flexShrink: 0 }}>
            <div>
              <b>Source</b>
            </div>
            {props.log.source}
          </div>
          <div style={{ flexShrink: 0 }}>
            <div>
              <b>Platform</b>
            </div>
            {props.log.platform}
          </div>
          <div style={{ flexShrink: 0 }}>
            <div>
              <b>Module</b>
            </div>
            <div>
              {props.log.module}
            </div>
          </div>
          <div style={{ flexShrink: 0 }}>
            <div>
              <b>Session ID</b>
            </div>
            <div>
              {props.log.sessionId}
            </div>
          </div>
          <div style={{ flexShrink: 0 }}>
            <div>
              <b>User ID</b>
            </div>
            <div>
              {props.log.userId}
            </div>
          </div>
          <div style={{ flexShrink: 0 }}>
            <div>
              <b>Tenant</b>
            </div>
            <div>
              {props.log.tenant ?? 'No Tenant'}
            </div>
          </div>
          <div style={{ flexShrink: 0 }}>
            <div>
              <b>Environment</b>
            </div>
            <div>
              {props.log.environment ?? 'No Envurionment'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

function sortAndDedupe (logs: StoredLogItem[]): StoredLogItem[] {
  const deduped: Record<string, StoredLogItem> = {};

  for (const log of logs) {
    deduped[log.uuid] = log;
  }

  return Object.values(deduped).sort((a, b) => b.date.valueOf() - a.date.valueOf());
}

const DisplayType = React.createContext<'logs' | 'traces'>('logs');

export const LogView = React.memo(function LogView (): JSX.Element {
  const [logs, setLogs] = React.useState<StoredLogItem[]>([]);
  const [displayType, setDisplayType] = React.useState<'logs' | 'traces'>('logs');
  const retrieveOlderLogsMutation = seamlessClient.useMutation<RetrieveLogsBP.Plug>(RetrieveLogsBP, { timeout: 60000 });
  const retrieveLogsMutation = seamlessClient.useMutation<RetrieveLogsBP.Plug>(RetrieveLogsBP, { timeout: 60000 });
  const filters: {
    kind?: StardateLogKind[]
  } | null = React.useMemo(() => {
    const usp = new URLSearchParams(window.location.search);

    const filters: RetrieveLogsBP.ParamsType['filters'] = {};

    if (usp.has('kind')) {
      filters.kind = usp.get('kind')?.split(',') as StardateLogKind[];
    }

    if (usp.has('message')) {
      filters.message = usp.get('message') ?? '';
    }

    if (usp.has('userId')) {
      filters.userId = usp.get('userId') ?? '';
    }

    if (usp.has('tenant')) {
      filters.tenant = usp.get('tenant') ?? '';
    }

    if (usp.has('platform')) {
      filters.platform = usp.get('platform') ?? '';
    }

    if (Object.keys(filters).length > 0) {
      return filters;
    }

    return null;
  }, []);

  React.useEffect(() => {
    const refresh = (): void => {
      void retrieveLogsMutation.call({
        before: new Date(),
        filters
      }).then(res => {
        setLogs(l => (sortAndDedupe([...l, ...res.logs])));
      });
    };

    refresh();

    const inv = setInterval(refresh, 5000);

    return () => {
      clearInterval(inv);
    };
  }, []);

  const loadOlder = React.useCallback(() => {
    void retrieveOlderLogsMutation.call({
      before: logs[logs.length - 1].date,
      filters
    }).then(res => {
      setLogs(l => (sortAndDedupe([...l, ...res.logs])));
    });
  }, [logs]);

  return (
    <DisplayType.Provider value={displayType}>
      {displayType === 'logs'
        ? <button onClick={() => setDisplayType('traces')}>Switch to Traces</button>
        : <button onClick={() => setDisplayType('logs')}>Switch to Logs</button>
      }
      <div style={{
        background: 'black',
        color: 'white',
        overflow: 'auto',
        height: '100%'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10
        }}>
          {logs.map(l => (
            <LogRow key={l.uuid} log={l}/>
          ))}
        </div>
        <div style={{ padding: 10 }}>
          <Button
            onClick={loadOlder}
            isLoading={retrieveOlderLogsMutation.isLoading}
            label="Load Older"
          />
        </div>
      </div>
    </DisplayType.Provider>
  );
});
